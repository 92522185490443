<template>
  <div class="wap-login user">
    <div class="flex-center-between top-info">
      <div class="back" @click="back">
        <i class="el-icon-arrow-left"></i>
      </div>
      <div class="flex-center lang" @click="toPath('wapLang')">
        <img :src="langUrl" alt="" />
      </div>
    </div>
    <div class="page-title">{{ $t('deng-lu-0') }}</div>

    <div class="tabs flex">
      <div
        class="tab"
        :class="tabIndex == 1 ? 'active' : ''"
        @click="changeTab(1)"
      >
        {{ $t('you-xiang-0') }}
      </div>
      <div
        class="tab"
        :class="tabIndex == 2 ? 'active' : ''"
        @click="changeTab(2)"
      >
        {{ $t('shou-ji-hao') }}
      </div>
    </div>

    <div class="login-form">
      <div class="form-item" v-if="tabIndex == 1">
        <div class="label">{{ $t('you-xiang-0') }}</div>
        <Field
          v-model="form.email"
          :placeholder="$t('qing-shu-ru-you-xiang-2')"
        />
      </div>
      <div class="form-item" v-if="tabIndex == 2">
        <div class="label">{{ $t('shou-ji-hao') }}</div>
        <Field
          v-model="form.phone"
          :placeholder="$t('qing-shu-ru-shou-ji-hao-4')"
        >
          <template #left-icon>
            <div class="flex-center" @click="changeCountry">
              {{ form.phonePre }}
              <i class="el-icon-caret-bottom"></i>
            </div>
          </template>
        </Field>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('mi-ma') }}</div>
        <Field
          v-model="form.password"
          :type="showPassword ? 'text' : 'password'"
          :placeholder="$t('qing-shu-ru-mi-ma-1')"
        >
          <template #button>
            <div class="flex-center" @click="showPassword = !showPassword">
              <img :src="eyeUrl" alt="" v-if="!showPassword" />
              <img :src="eyeUrl2" alt="" v-else />
            </div>
          </template>
        </Field>
      </div>
    </div>

    <div class="form-info flex-center-between">
      <div>
        {{ $t('ru-guo-nin-mei-you-zhang-hao') }}
        <span class="blue" @click="toPath('wapUserRegister')">{{
          $t('dian-ji-zhu-ce')
        }}</span>
      </div>
      <div class="blue">{{ $t('wang-ji-mi-ma-0') }}</div>
    </div>

    <div class="form-btn">
      <Button type="info" @click="submit">{{ $t('deng-lu-0') }}</Button>
    </div>

    <div class="kefu flex-center" @click="toKefu">
      <i class="el-icon-service"></i>
    </div>

    <CountryList
      :showDialog.sync="showDialog"
      :phonePre.sync="form.phonePre"
    ></CountryList>
  </div>
</template>
<script>
import { Button, Field } from 'vant'
import CountryList from '@/components/countryList'
import { login } from '@/api/login'
import { setToken, setUser, setUserId, setUserType } from '@/utils/auth'
export default {
  components: {
    Button,
    Field,
    CountryList
  },
  data() {
    return {
      form: {
        email: '',
        phone: '',
        phonePre: '+86',
        password: ''
      },
      showPassword: false,
      showDialog: false,
      tabIndex: 1,
      langUrl: require('@/assets/imgs/icon-lang.png'),
      eyeUrl: require('@/assets/imgs/icon-eye.png'),
      eyeUrl2: require('@/assets/imgs/icon-eye2.png')
    }
  },
  computed: {
    langIcon() {
      return this.$store.state.langIcon
    },
    lang() {
      return this.$store.state.lang
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changeTab(index) {
      this.tabIndex = index
    },
    submit() {
      if (this.tabIndex === 1 && !this.form.email) {
        this.$toast(this.$t('qing-shu-ru-you-xiang-0'))
        return
      }
      if (this.tabIndex === 2 && !this.form.phone) {
        this.$toast(this.$t('qing-shu-ru-shou-ji-hao-1'))
        return
      }
      if (!this.form.password) {
        this.$toast(this.$t('qing-shu-ru-mi-ma-0'))
        return
      }
      login({
        Account:
          this.tabIndex === 1
            ? this.form.email
            : `${this.form.phonePre}${this.form.phone}`,
        Password: this.form.password
      }).then((res) => {
        if (res.code == 0) {
          this.$toast(this.$t('deng-lu-cheng-gong'))
          let data = res.data
          this.$store.state.userType = data.business
          this.$store.state.isLogin = true
          setUserId(data.id)
          setUserType(data.business)
          setToken(data.token)
          this.$router.push({
            name: data.business == 1 ? 'wapHome' : 'wapIndex'
          })
        } else {
          this.$toast(res.msg)
        }
      })
    },
    changeCountry() {
      this.showDialog = true
    },
    toPath(path) {
      this.$router.push({
        name: path
      })
    },
    toKefu() {
      this.$router.push({
        name: 'wapKefu'
      })
    }
  }
}
</script>